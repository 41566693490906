:root {
  --light: #f7f7f7;
  --light-accent: #89afba;
  --main-brand: #0f1f2f;
  --dark-accent: #baa475;
  --dark: #2d3338;
  /*--invert: #f39c12;*/
  --invert: #e67e22;
}

* {
  box-sizing: border-box;
  border: 0;
  padding: 0;
  margin: 0;
}

html {
  font-size: clamp(1rem, 3vw, 1.4rem);
}

body {
  min-height: 100%;
  width: 100%;
  font-family: 'Merriweather', serif;
  color: var(--main-brand);
  letter-spacing: 0.03em;
}

/* SMALL SCREENS */

@media screen and (max-width: 350px) {
  html {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 339px) {
  h1 {
    margin: 1rem 0rem;
  }

  .brand {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 350px) and (max-width: 400px) {
  .brand {
    font-size: 0.9rem;
  }
}

/* BIGGER MOBILES */

@media screen and (max-width: 500px) and (min-height: 700px) {
  h1 {
    font-size: 2.5rem !important;
  }

  .copy h2,
  .verksamhet-copy h2,
  .anlaggning-copy h2 {
    font-size: 1.4rem !important;
  }
}

/* NORMAL CSS */

.hidden {
  display: none !important;
}

a {
  text-decoration: none;
  color: var(--dark-accent);
}

a:visited {
  color: var(--dark-accent);
}

/* BUTTONS */

button {
  padding: 0.7rem 1.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  letter-spacing: 2px;
  box-shadow: 1px 0px 2px var(--main-brand);
  cursor: pointer;
}

.btn-read-more {
  background-color: var(--light-accent);
  /* background: rgb(15, 31, 47);
  background: linear-gradient(
    360deg,
    rgba(15, 31, 47, 1) 0%,
    rgba(137, 175, 186, 1) 100%
  ); */
  /* color: var(--light); */
}

.btn-contact {
  background-color: var(--invert);
  margin-left: 1rem;
}

.btn-hitta-hit {
  background-color: var(--light-accent);
}

.btn-bilder {
  background-color: var(--invert);
  margin-right: 1rem;
}

.btn-motto {
  background-color: var(--invert);
  margin-right: 1rem;
}

.btn-verksamheter {
  background-color: var(--light-accent);
}

.btn-card {
  position: absolute;
  right: 0;
  bottom: 0;
  color: var(--main-brand);
  background-color: var(--light-accent);
  border: 0;
  box-shadow: 0px 0px 0px;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-intresse {
  background-color: var(--invert);
  margin-right: 1rem;
}

/* Scroll down arrow */

.fa-angle-double-down {
  position: absolute;
  font-size: 2.5rem;
  color: var(--dark-accent);
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.7;
}

/* NAV */
nav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 4rem;
  width: 100%;
  background-color: var(--main-brand);
  z-index: 99;
}

.logo * {
  display: inline-block;
  vertical-align: middle;
}

.logo {
  flex-grow: 0;
  flex-shrink: 0;
}

.logo img {
  width: auto;
  max-height: 3.9rem;
}

.brand {
  text-transform: uppercase;
  font-weight: lighter;
  font-size: 1rem;
  color: var(--dark-accent);
  margin-bottom: 0;
}

.menu {
  flex-grow: 0;
}

.d-menu {
  display: none;
  width: 100%;
}

.d-menu ul {
  display: flex;
  height: 100%;
  flex-grow: 1;
  list-style: none;
  font-size: 0.7rem;
  justify-content: flex-end;
  align-items: center;
}

.d-menu li {
  text-decoration: none;
  margin-right: 1rem;
}

.d-menu a:hover {
  border-bottom: 3px solid var(--dark-accent);
}

.fa-bars {
  font-size: 2.5rem;
  padding: 0.7rem 1rem;
  color: var(--dark-accent);
}

.btn-top {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.5rem;
  font-size: 3rem;
  color: var(--dark-accent);
  background-color: var(--main-brand);
  border-top-left-radius: 50%;
  z-index: 99;
  opacity: 0.9;
}

/* MENU MOBILE */

.m-menu {
  position: fixed;
  text-align: center;
  top: 4rem;
  right: 0;
  width: 70%;
  color: var(--dark-accent);
  background: var(--main-brand);
  z-index: 99;
  transition: transform 0.5s;
  transform: translateX(150%);
  border-bottom-left-radius: 1rem;
}

.m-menu li {
  text-align: right;
  font-size: 1.2rem;
  font-weight: 300;
  letter-spacing: 0.3rem;
  padding: 1.5rem;
  text-transform: uppercase;
  list-style: none;
  border-bottom: 1px solid var(--dark-accent);
}

.m-menu li:last-of-type {
  border: 0;
}

.m-menu a {
  text-decoration: none;
  color: #baa475;
}

.center {
  text-align: center;
}

/* HERO */

h1 {
  font-size: 2.2rem;
  text-align: center;
  font-weight: lighter;
  margin-top: 1rem;
  margin-bottom: 2rem;
  line-height: 130%;
  color: var(--light);
  text-shadow: 1px 4px 5px var(--main-brand);
}

.entry-page {
  min-height: -webkit-fill-available;
  height: 100vh;
  width: 100%;
  /* background-color: var(--main-brand); */
  background-image: url(/Images/fea48939.png);
}

.hero {
  height: 100%;
  width: 100%;
  background: url(/Images/uppfart2-m.jpeg);
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.7);
  background-repeat: no-repeat;
}

.copy {
  padding: 1rem;
  /* border-radius: 0.5rem; */
  z-index: 2;
  color: black;
  text-shadow: 5px 4px 5px var(--main-brand);
}

.copy h2 {
  max-width: 45ch;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 150%;
  color: var(--light);
  font-weight: 300;
}

p {
  line-height: 150%;
  font-size: 1rem;
}

.buttons {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
}

/* STABLES */

.stables {
  display: flex;
  flex-grow: 1;
  gap: 1rem;
  padding-bottom: 1rem;
  flex-direction: column;
  max-width: 100%;
  text-align: center;
  /* background-color: var(--dark); */
  overflow-x: hidden;
}

.partners h2,
.social h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.partners {
  position: relative;
  background-color: white;
  min-height: 16rem;
  max-height: 100%;
  width: auto;
  color: var(--dark);
  padding: 2rem 1rem;
}

.partners p,
.program p {
  max-width: 45ch;
  margin: 0 auto;
}

.stables ul {
  max-width: calc(100% - 2rem);
  /* max-width: max-content; */
  margin: 0 auto;
  text-align: left;
}

.stables li {
  font-size: 1rem;
  margin-bottom: 1em;
}

.fa-li {
  color: var(--dark);
}

.bx {
  /* background-color: var(--light); */
  background-image: url(/Images/bxcard-min.jpeg);
  background-size: cover;
  height: 24rem;
  max-width: 100%;
  color: var(--main-brand);
  padding: 2rem;
}

.bx img {
  width: 6rem;
  height: auto;
  padding: 1rem;
  margin: 0 auto;
  /* margin-left: 50%;
  transform: translate(-50%); */
  border-radius: 50%;
}

.bx-overlay {
  position: relative;
  background-color: #f7f7f7;
  height: 100%;
  border-radius: 0.5rem;
  padding: 2rem;
  opacity: 0.9;
}

.nova {
  background-image: url(/Images/novacard-min.jpeg);
  background-size: cover;
  box-shadow: inset 0 0 0 50vh rgba(0, 0, 0, 0.278);
  height: 24rem;
  width: auto;
  color: var(--main-brand);
  padding: 2rem;
}

.nova img {
  position: relative;
  width: 12rem;
  max-width: 100%;
  height: auto;
  /* margin: 0 auto; */
  padding: 1rem 0rem;
}

.nova-overlay {
  position: relative;
  background-color: #f7f7f7;
  height: 100%;
  border-radius: 0.5rem;
  padding: 2rem;
  opacity: 0.9;
}

.blacksmith {
  /* background-color: var(--light); */
  background-image: url(/Images/hast1-min.jpeg);
  background-size: cover;
  height: 24rem;
  width: auto;
  color: var(--main-brand);
  padding: 2rem;
}

.blacksmith h2 {
  text-align: center;
  padding: 1rem;
  color: var(--dark);
  font-weight: 700;
}

.blacksmith-overlay {
  position: relative;
  background-color: #f7f7f7;
  height: 100%;
  border-radius: 0.5rem;
  padding: 2rem;
  opacity: 0.9;
}

.long-card {
  height: 26rem;
}

.long-card p {
  text-align: left;
}

/* SOCIAL */

.social {
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
}

.social h2 {
  padding: 2rem 1rem;
  text-align: center;
  color: var(--dark);
}

/* FOOTER */

footer {
  height: 6rem;
  width: 100%;
  background-image: url(/Images/fea48939.png);
  color: var(--dark-accent);
  font-size: 3rem;
  padding: 1rem;
}

.social-links {
  display: flex;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.copyright {
  background-image: url(/Images/fea48939.png);
  color: #f7f7f7;
  text-align: center;
  font-size: 0.8rem;
  width: 100%;
}

/* 
ANLÄGGNINGEN
*/

.anlaggning-copy {
  height: 100%;
  padding: 1rem;
  /* background-color: var(--light); */
  background-image: url(/Images/ridhus2-min.jpeg);
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: inset 0 0 0 50vh rgba(0, 0, 0, 0.7);
  background-size: cover;
}

.anlaggning-copy h2 {
  font-weight: 300;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 150%;
  letter-spacing: 0.025em;
  max-width: 45ch;
  /* background-color: #f7f7f7e3; */
  border-radius: 0.5rem;
  color: var(--light);
  text-shadow: 1px 4px 5px var(--main-brand);
}

.anlaggning-photos {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* gap: 0.5rem; */
  background-color: var(--light);
}

.anlaggning-photos-flex {
  display: none;
}

.anlaggning-row {
  background-color: var(--main-brand);
}

.anlaggning-row img {
  max-width: 100%;
  height: auto;
}

.anlaggning-h2 {
  background-image: url(/Images/fea48939.png);
  color: var(--light);
  line-height: 140%;
  padding: 1rem;
  font-size: 1.2rem;
}

.vagbeskrivning {
  padding: 2rem 1rem;
}

.hitta-hit {
  padding: 1rem;
}

.hitta-hit h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.google-maps {
  width: 100%;
  height: 450px;
}

/* VERKSAMHET */

.verksamhet-copy {
  background-image: url(/Images/ryttare8-min.jpg);
  background-size: cover;
  height: 100%;
  background-position: center;
  /* background-position-x: -12rem; */
  box-shadow: inset 0 0 0 50vh rgba(0, 0, 0, 0.7);
  padding: 1rem;
  background-repeat: no-repeat;
}

.verksamhet-copy h2 {
  font-weight: 300;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 150%;
  letter-spacing: 0.025em;
  max-width: 45ch;
  /* background-color: #f7f7f7e3; */
  border-radius: 0.5rem;
  color: var(--light);
  text-shadow: 1px 4px 5px var(--main-brand);
}

.verksamheter {
  display: flex;
  flex-grow: 1;
  gap: 1rem;
  padding-bottom: 1rem;
  flex-direction: column;
  max-width: 100%;
  text-align: center;
  /* background-color: var(--dark); */
  overflow-x: hidden;
}

.verksamheter p {
  max-width: 45ch;
}

.motto {
  padding: 2rem;
  background-image: url(/Images/fea48939.png);
  color: var(--dark-accent);
  text-align: left;
}

.motto h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 1rem;
}

/* BLACKSMITH */

.blacksmith-copy {
  background-image: url(/Images/lunch2-min-m.jpeg);
  background-size: cover;
  background-position: center;
  height: calc(100% - 4rem);
  box-shadow: inset 0 0 0 100vw rgba(0, 0, 0, 0.7);
  padding: 1rem;
  background-repeat: no-repeat;
}

.blacksmith-copy h2 {
  font-weight: 300;
  font-size: 1.2rem;
  margin-bottom: 2rem;
  line-height: 150%;
  letter-spacing: 0.025em;
  max-width: 45ch;
  /* background-color: #f7f7f7e3; */
  border-radius: 0.5rem;
  color: var(--light);
  text-shadow: 1px 4px 5px var(--main-brand);
}

.program {
  padding: 2rem 1rem;
}

.program h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.contact-form-amicus {
  width: 100%;
  background-color: var(--main-brand);
  color: var(--light);
  padding: 1rem;
}

.contact-form-amicus h2 {
  padding-top: 1rem;
}

.contact-form-amicus p {
  padding: 2rem 0rem;
}

.blacksmith-photos {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  gap: 0.5rem;
  /* background-color: var(--main-brand); */
}

/* KONTAKT */

.contact-form {
  background-image: url(/Images/fea48939.png);
  color: var(--light);
  padding: 1rem;
}

.force-blue {
  background: var(--main-brand) !important;
}

.contact-form p {
  margin-bottom: 1rem;
}

form input,
textarea,
select {
  padding: 0.5rem;
  width: 100%;
  font-size: 1rem;
  margin-bottom: 1rem;
  font: inherit;
}

input,
select {
  min-height: 48px;
  min-width: 48px;
}

#news,
#gdpr {
  width: fit-content;
  float: right;
}

.consent {
  display: inline-block;
  width: 80%;
}

#submit {
  background: var(--dark-accent);
  font-size: 2rem;
  letter-spacing: 0.3rem;
  font-weight: 300;
}

.full-height {
  height: 100vh;
}
/* ALERTS */

.alert {
  position: fixed;
  width: 50vw;
  top: 50vh;
  left: 25vw;
  text-align: center;
  padding: 4rem;
  background-color: var(--light);
  border-radius: 1rem;
}

/* COOKIE MESSAGE */

.cookies {
  position: fixed;
  display: inline-flex;
  width: 100%;
  bottom: 0vh;
  padding: 1rem;
  color: var(--light);
  background-color: black;
  z-index: 99;
}

.cookies span {
  font-size: 0.7rem;
  align-self: center;
  margin-right: 1em;
}

.btn-cookies {
  padding: 1rem;
  border-radius: 1rem;
  background: transparent;
  color: var(--light);
  border: 2px solid var(--light);
}

/* RECAPTCHA */

.grecaptcha-badge {
  display: none !important;
}

.captcha-terms-div {
  width: 100%;
}

.captcha-terms-div p {
  color: grey;
}

.captcha-terms-div a {
  color: blue;
}
